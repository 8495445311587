import { css } from '@emotion/react';

const styles = (props) => {
  const {
    theme: {
      breakpointS,
      spacingM,
      colorBackground
    },
  } = props;

  return css`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: ${colorBackground};

    display: flex;
    align-items: center;
    justify-content: start;
    height: 56px;

    @media (min-width: ${breakpointS}) {
      height: 72px;
    }

    .bottombar-actions {
      margin-left: ${spacingM};
    }
  `
}

export default styles;
