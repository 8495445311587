import React from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import MUITextField from '@material-ui/core/TextField';
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { cosmicpuma } from '@nonsequitur/component-library';

const { Button, DropDown, TextField } = cosmicpuma;

const PublishModal = (props) => {
  const {
    isFullScreen,
    isModalOpen,
    setIsModalOpen,
    description,
    category,
    tags,
    onChangeDescription,
    onChangeCategory,
    onChangeTags,
    categoryOptions,
    tagOptions,
    className,
    classes
  } = props;

  const handleCloseModal = (event) => {
    event.preventDefault();
    setIsModalOpen(false);
  };

  const handleChangeDescription = (event) => {
    event.preventDefault();
    const description = event.target.value;
    onChangeDescription(description);
  };

  const handleChangeCategory = (event) => {
    event.preventDefault();
    const category = event.target.value;
    onChangeCategory(category);
  };

  const handleChangeTags = (event, newValue) => {
    event.preventDefault();
    onChangeTags(newValue);
  };

  const handlePublish = (event) => {
    event.preventDefault();
    console.log('dookie')
  };

  return (
    <Dialog
      fullScreen={isFullScreen}
      open={isModalOpen}
      onBackdropClick={handleCloseModal}
      onEscapeKeyDown={handleCloseModal}
      className={className}
      classes={{
        paper: "dialog-root"
      }}
    >
      <Title
        classes={classes}
        onClose={handleCloseModal}
      >
        Publish
      </Title>
      <Content
        classes={classes}
        description={description}
        category={category}
        tags={tags}
        categoryOptions={categoryOptions}
        tagOptions={tagOptions}
        onChangeDescription={handleChangeDescription}
        onChangeCategory={handleChangeCategory}
        onChangeTags={handleChangeTags}
      />
      <Actions
        classes={classes}
        onPublish={handlePublish}
      />
    </Dialog>
  );
};

const Title = (props) => {
  const { children, classes, onClose } = props;
  return (
    <DialogTitle className={classes.titleBar} disableTypography>
      <div className={classes.title}>{children}</div>
      <IconButton
        aria-label="close-publish-mod"
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};

const Content = (props) => {
  const {
    classes,
    description,
    category,
    tags,
    onChangeDescription,
    onChangeCategory,
    onChangeTags,
    categoryOptions,
    tagOptions,
  } = props;
  return (
    <DialogContent className={classes.content}>
      <TextField
        id="article-description-input"
        name="article-description-input"
        type="text"
        placeholder="Enter a description..."
        label="Description"
        maxLength={256}
        isRequired={true}
        showCharacterCount={true}
        value={description}
        onChange={onChangeDescription}
        className={classes.descriptionInput}
      />
      <DropDown
        id="article-category-select"
        name="article-category-select"
        label="Category"
        placeholder="Choose a category..."
        isRequiredField={true}
        value={category}
        onChange={onChangeCategory}
        options={categoryOptions}
        className={classes.categoryInput}
      />
      <Autocomplete
        id="article-tags-multi-select"
        value={tags}
        onChange={onChangeTags}
        options={tagOptions}
        getOptionLabel={(option) => option.label}
        multiple
        filterSelectedOptions
        popupIcon={<ExpandMoreIcon />}
        renderInput={(params) => (
          <MUITextField
            {...params}
            label="Tags"
            placeholder="Choose tags..."
            variant="outlined"
            required={true}
            className={classes.tagsInput}
          />
        )}
      />
    </DialogContent>
  );
};

const Actions = (props) => {
  const { classes, onPublish } = props;
  return (
    <DialogActions className={classes.actionBar}>
      <Button
        className={classes.actions}
        onClick={onPublish}
        color="secondary"
        variant="solid"
        size="small"
        children="Save & Publish"
        ariaLabel="Save and Publish"
      />
    </DialogActions>
  );
};

PublishModal.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  isFullScreen: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func
};

PublishModal.defaultProps = {
  className: undefined,
  isFullScreen: false,
  isModalOpen: false,
  setIsModalOpen: undefined,
  classes: {
    titleBar: 'publish-modal-title-bar',
    title: 'publish-modal-title',
    closeButton: 'publish-modal-close-button',
    content: 'publish-modal-content',
    descriptionInput: 'publish-modal-description-input',
    categoryInput: 'publish-modal-category-input',
    tagsInput: 'publish-modal-tags-input',
    actionBar: 'publish-modal-action-bar',
    actions: 'publish-modal-actions',
  }
}

export default PublishModal;
