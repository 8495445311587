import React, { useState, useReducer } from "react"
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';

import Layout from "../components/layout"
import Editor from "../components/Editor"
import TitleInput from "../components/TitleInput"
import PublishModal from "../components/PublishModal"
import BottomBar from "../components/BottomBar"
import getCategories from '../services/getCategories'
import getTags from '../services/getTags'
import { useLocalStorage, useMediaQuery, useAsyncFn } from '../utils/hooks'
import { articleMetadataReducer, articleMetadataInitialState } from '../utils/reducers'

const defaultInitialValue = [
  {
    children: [
      {
        text: '',
      },
    ],
  },
]

const Write = (props) => {
  const {
    className
  } = props;

  // Screen size
  const { breakpointS } = useTheme();
  const isSmallScreen = useMediaQuery(`(max-width: ${breakpointS})`)

  // Local state
  const [metadataState, metadataDispatch] = useReducer(articleMetadataReducer, articleMetadataInitialState)
  const [text, setTextToLocalStorage] = useLocalStorage('articleIdHere', defaultInitialValue); // make more dynamic key
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);

  // Async data load
  // eslint-disable-next-line no-unused-vars
  const [{ isLoading: isLoadingCategories, isError: isErrorCategories, data: categoryOptions }] = useAsyncFn(getCategories);
  // eslint-disable-next-line no-unused-vars
  const [{ isLoading: isLoadingTags, isError: isErrorTags, data: tagOptions }] = useAsyncFn(getTags);

  const handlePublishModal = (event) => {
    event.preventDefault();
    setIsPublishModalOpen(true);
  }

  const handleSaveDraft = (event) => {
    event.preventDefault();
    console.log("SAVED")

    // return Promise.all(
    //   [
    //     articlesClient.updateArticleText({
    //       articleId: '', // TODO: where do you come from
    //       documentData: text
    //     }),
    //     articlesClient.updateArticle(metadataState)
    //   ]
    // )
  }

  const handleOnChangeMetadata = (key) => (value) => {
    metadataDispatch({ type: 'ARTICLE_UPDATE', key, value })
  }

  const bottomBarActions = [
    {
      color: 'secondary',
      variant: 'solid',
      size: 'small',
      children: 'Publish',
      onClick: handlePublishModal
    },
    {
      color: 'primary',
      variant: 'outlined',
      size: 'small',
      children: 'Save Draft',
      onClick: handleSaveDraft
    },
  ];

  return (
    <Layout>
      <div className={className}>
        <TitleInput
          title={metadataState.title}
          onChangeTitle={handleOnChangeMetadata('title')}
        />
        <Editor
          initialValue={text}
          setText={setTextToLocalStorage}
        />
        <BottomBar
          actions={bottomBarActions}
        />
        <PublishModal
          isFullScreen={isSmallScreen}
          isModalOpen={isPublishModalOpen}
          setIsModalOpen={setIsPublishModalOpen}
          description={metadataState.description}
          category={metadataState.category}
          tags={metadataState.tags}
          onChangeDescription={handleOnChangeMetadata('description')}
          onChangeCategory={handleOnChangeMetadata('category')}
          onChangeTags={handleOnChangeMetadata('tags')}
          categoryOptions={categoryOptions}
          tagOptions={tagOptions}
        />
      </div>
    </Layout>
  )
}

Write.propTypes = {
  className: PropTypes.string
}

Write.defaultProps = {
  className: undefined
}

export default Write;
