import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';

import Unstyled from './BottomBar';
import styles from './styles';


const BottomBar = styled(Unstyled)`
  ${styles}
`;

BottomBar.defaultProps = {
  ...Unstyled.defaultProps,
};

export default withTheme(BottomBar);
