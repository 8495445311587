import { css } from '@emotion/react';

const styles = (props) => {
  const {
    theme: {
      spacingS,
      spacingM,
      spacingL,
      typographyHeader2 = {}, // TODO: fix this build-time hack
      typographyBody2 = {}, // TODO: fix this build-time hack
      colorPrimary,
      colorBackground,
      colorText,
      colorTextSecondary,
      colorBorder,
      colorBorderFocus,
      borderRadiusS,
      colors = {} // TODO: fix this build-time hack
    },
  } = props;

  return css`
    .dialog-root {
      width: 100%;
      background: ${colorBackground};
    }

    .publish-modal-title-bar {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 56px;
    }

    .publish-modal-title {
      margin-right: auto;
      font-family: ${typographyHeader2.fontFamily};
      font-size: ${typographyHeader2.fontSize};
      font-weight: ${typographyHeader2.fontWeight};
      color: ${colorPrimary};
    }

    .publish-modal-close-button {
      position: "absolute";
      color: ${colors.gray50};
    }

    .publish-modal-description-input {

    }

    .publish-modal-category-input {
      margin-top: ${spacingM};
    }

    .publish-modal-tags-input {
      margin-top: ${spacingL};

      label {
        font-family: ${typographyBody2.fontFamily};
        font-size: ${typographyBody2.fontSize};
        font-weight: ${typographyBody2.fontweight};
        color: ${colorTextSecondary};
      }

      input {
        color: ${colorText};

        ~ fieldset {
            border-style: solid;
            border-radius: ${borderRadiusS};
            border-color: ${colorBorder};
          }

        &:hover,
        &:focus {
          outline: 0;

          ~ fieldset {
            border-color: ${colorBorderFocus};
          }

          ~ label {
            color: ${colorText};
          }
        }
      }

      svg {
        color: ${colorText};
      }
    }

    .publish-modal-action-bar {
      margin-top: ${spacingM};
      margin-bottom: ${spacingS};
      margin-right: 16px;
    }
  `
}

export default styles;
