import React from 'react';
import PropTypes from 'prop-types';
import { cosmicpuma } from '@nonsequitur/component-library';

const { Button } = cosmicpuma;

const BottomBar = (props) => {
  const {
    actions,
    classes,
    className,
  } = props;

  return (
    <div className={className}>
      {actions.map(props => (
        <Button
          {...props}
          className={classes.action}
          key={props.children}
        />
      ))}
    </div>
  );
}

BottomBar.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object).isRequired,
  classes: PropTypes.shape({
    actions: PropTypes.string,
  }),
  className: PropTypes.string
}

BottomBar.defaultProps = {
  classes: {
    action: 'bottombar-actions'
  },
  className: undefined
}

export default BottomBar;
