const initialState = {
  title: '',
  description: '',
  category: '',
  tags: [],
};

const articleMetadataReducer = (state, action) => {
  switch (action.type) {
    case 'ARTICLE_RESET':
      return initialState;
    case 'ARTICLE_UPDATE':
      return {
        ...state,
        [action.key]: action.value
      };
    default:
      throw new Error();
  }
}

export { articleMetadataReducer, initialState };
